import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import Roles from '../../components/Roles/Roles';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Lehrbeauftragte`}</h1>
      <h2 {...{
        "id": "lehrbeauftragter-fur-homoopathie-notfalle-in-der-praxis-chiropraktik-und-psychotherapie"
      }}>{`Lehrbeauftragter für Homöopathie, Notfälle in der Praxis, Chiropraktik und Psychotherapie`}</h2>
      <Roles role_name='Lehrbeautragter-Homöo' mdxType="Roles" />
      <h2 {...{
        "id": "lehrbeauftragte-fur-naturheilverfahren"
      }}>{`Lehrbeauftragte für Naturheilverfahren`}</h2>
      <Roles role_name='Lehrbeautragte-Naturheilverfahren' mdxType="Roles" />
      <h2 {...{
        "id": "lehrbeauftragter-fur-neuraltherapie-notfalle-in-der-praxis-gutachter-fur-hp-abrechnungen-nach-gebuh"
      }}>{`Lehrbeauftragter für Neuraltherapie, Notfälle in der Praxis Gutachter für HP-Abrechnungen nach GebüH`}</h2>
      <Roles role_name='Lehrbeautragte-Neuraltherapie' mdxType="Roles" />
      <h2 {...{
        "id": "lehrbeauftragte-fur-osteopathie"
      }}>{`Lehrbeauftragte für Osteopathie`}</h2>
      <Roles role_name='Lehrbeautragte-Osteopathie' mdxType="Roles" />
      <h2 {...{
        "id": "lehrbeauftragte-fur-biochemie-nach-dr-schussler"
      }}>{`Lehrbeauftragte für Biochemie nach Dr. Schüßler`}</h2>
      <Roles role_name='Lehrbeautragte-Biochemie' mdxType="Roles" />
      <h2 {...{
        "id": "lehrbeauftragte-fur-tcmakupunktur-yoga-therapie"
      }}>{`Lehrbeauftragte für TCM/Akupunktur, Yoga-Therapie`}</h2>
      <Roles role_name='Lehrbeautragte-Akupunktur' mdxType="Roles" />
      <h2 {...{
        "id": "lehrbeauftragter-fur-dorn-methodebreuss-massage"
      }}>{`Lehrbeauftragter für Dorn-Methode/Breuss-Massage`}</h2>
      <Roles role_name='Lehrbeautragte-Dorn' mdxType="Roles" />
      <h2 {...{
        "id": "lehrbeauftragte-fur-energetisches-heilen-reiki-meditation-psychosomatik"
      }}>{`Lehrbeauftragte für Energetisches Heilen, Reiki, Meditation, Psychosomatik`}</h2>
      <Roles role_name='Lehrbeautragte-Energetisches-Heilen' mdxType="Roles" />
      <h2 {...{
        "id": "lehrbeauftragter-fur-phytotherapie-homoopathie-pharmakologie"
      }}>{`Lehrbeauftragter für Phytotherapie, Homöopathie, Pharmakologie`}</h2>
      <Roles role_name='Lehrbeautragte-Phytotherapie' mdxType="Roles" />
      <h2 {...{
        "id": "lehrbeauftragte-fur-akupunktur"
      }}>{`Lehrbeauftragte für Akupunktur`}</h2>
      <Roles role_name='Lehrbeautragte-Akupunktur-2' mdxType="Roles" />
      <h2 {...{
        "id": "lehrbeauftragte-fur-korperorientierte-psychotherapie-bioenergetik"
      }}>{`Lehrbeauftragte für Körperorientierte Psychotherapie (Bioenergetik)`}</h2>
      <Roles role_name='Lehrbeautragte-Bioenergetik' mdxType="Roles" />
      <h2 {...{
        "id": "lehrbeauftragte-fur-emdr"
      }}>{`Lehrbeauftragte für EMDR`}</h2>
      <Roles role_name='Lehrbeautragte-EMDR' mdxType="Roles" />
      <h2 {...{
        "id": "lehrbeauftragte-fur-hypnosetherapie"
      }}>{`Lehrbeauftragte für Hypnosetherapie`}</h2>
      <Roles role_name='Lehrbeautragte-Hypnosetherapie' mdxType="Roles" />
      <h2 {...{
        "id": "lehrbeauftragte-fur-homoopathie-ernahrung-dorn--breuss-bach-bluten"
      }}>{`Lehrbeauftragte für Homöopathie, Ernährung, Dorn & Breuß, Bach-Blüten`}</h2>
      <Roles role_name='Lehrbeautragte-Ernährung' mdxType="Roles" />
      <h2 {...{
        "id": "lehrbeauftragte-fur-tierheilkunde"
      }}>{`Lehrbeauftragte für Tierheilkunde`}</h2>
      <Roles role_name='Lehrbeautragte-Tierheilkunde' mdxType="Roles" />
      <h2 {...{
        "id": "lehrbeauftragte-fur-blutegeltherapie"
      }}>{`Lehrbeauftragte für Blutegeltherapie`}</h2>
      <Roles role_name='Lehrbeauftragte-für-Blutegeltherapie' mdxType="Roles" />
    </Wrapper>
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      